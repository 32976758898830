import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Badge,
  Tooltip,
} from '@mui/material';
import { useApi } from '../../../components/api/useApi';
import { filteredResources } from '../../../components/api/ApiFunctions';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotesIcon from '@mui/icons-material/Notes';

const Dashboards = () => {
  const [openProjects, setOpenProjects] = useState(null);
  const [openTasks, setOpenTasks] = useState(null);
  const [startedTasks, setStartedTasks] = useState(null);

  const [openProjectsData, setOpenProjectsData] = useApi(() =>
    filteredResources('projects/filtered', {
      filters: { active: true },
    })
  );
  const [openTasksData, setOpenTasksData] = useApi(() =>
    filteredResources('tasks/filtered', {
      filters: { active: true },
    })
  );

  useEffect(() => {
    setOpenTasksData();
    setOpenProjectsData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      openTasksData.error === null &&
      !openTasksData.isFetching &&
      openTasksData.isSuccess
    ) {
      setOpenTasks(openTasksData.data);
      startedTasksCount(openTasksData.data);
    }
  }, [openTasksData]);

  useEffect(() => {
    if (
      openProjectsData.error === null &&
      !openProjectsData.isFetching &&
      openProjectsData.isSuccess
    ) {
      setOpenProjects(openProjectsData.data);
    }
  }, [openProjectsData]);

  const startedTasksCount = (tasks) => {
    const workingTasks = tasks.filter((task) => task.startDate !== null);
    setStartedTasks(workingTasks);
  };

  const lookupProjectName = (id) => {
    const result = openProjects.filter((project) => project._id === id);

    return result[0].projectName;
  };

  return (
    <Paper elevation={4} sx={{ p: 3 }}>
      {openTasks !== null && openProjects !== null ? (
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Card elevation={4}>
              <CardContent>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip title="Open Projects">
                      <Badge badgeContent={openProjects.length} color="success">
                        <DashboardIcon color="success" />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Tooltip title="Open Tasks">
                      <Badge badgeContent={openTasks.length} color="warning">
                        <NotesIcon color="warning" />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Tooltip title="Started Tasks">
                      <Badge badgeContent={startedTasks.length} color="success">
                        <NotesIcon color="success" />
                      </Badge>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : null}

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card elevation={4}>
            <CardContent>
              <Typography variant="h5">Open Projects</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ bgcolor: '#575151' }}>
                    <TableCell sx={{ color: 'white' }}>Project</TableCell>
                    <TableCell sx={{ color: 'white' }}>Customer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {openProjects !== null
                    ? openProjects.map((project) => (
                        <TableRow key={project._id}>
                          <TableCell>{project.projectName}</TableCell>
                          <TableCell>{project.customer}</TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h5">Tasks In Progress</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ bgcolor: '#575151' }}>
                    <TableCell sx={{ color: 'white' }}>Task</TableCell>
                    <TableCell sx={{ color: 'white' }}>Project</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {startedTasks !== null && openProjects !== null
                    ? startedTasks.map((task) => (
                        <TableRow key={task._id}>
                          <TableCell>{task.task}</TableCell>
                          <TableCell>
                            {lookupProjectName(task.taskProjectId)}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ pl: { xs: 0, sm: 1 }, mt: { xs: 2, sm: 0 } }}
        >
          <Card elevation={4}>
            <CardContent>
              <Typography variant="h5">Open Tasks</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ bgcolor: '#575151' }}>
                    <TableCell sx={{ color: 'white' }}>Task</TableCell>
                    <TableCell sx={{ color: 'white' }}>Project</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {openTasks !== null && openProjects !== null
                    ? openTasks.map((task) => (
                        <TableRow key={task._id}>
                          <TableCell>{task.task}</TableCell>
                          <TableCell>
                            {lookupProjectName(task.taskProjectId)}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboards;
