import axios from 'axios';

const baseURL = '/api';

// Handles GET Requests
export const fetchResources = async (resource) => {
  try {
    const response = await axios.get(`${baseURL}/${resource}`);
    return await response.data;
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    throw err;
  }
};

// Handles POST Requests
export const postResource = async (resource, data) => {
  try {
    const response = await axios.post(`${baseURL}/${resource}`, data);
    return response.data;
  } catch (err) {
    console.log(`${err}`);
    throw err;
  }
};

// Handles GET Requests for a specific resource
export const fetchResource = async (resource, id) => {
  try {
    const response = await axios.get(`${baseURL}/${resource}/${id}`);
    return await response.data;
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    throw err;
  }
};

// Handles PUT Requests for resource
export const putResource = async (resource, data, id) => {
  try {
    const response = await axios.put(`${baseURL}/${resource}/${id}`, data);
    return await response.data;
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    throw err;
  }
};
// Handles Delete Requests for resource
export const deleteResource = async (resource, id) => {
  try {
    const response = await axios.delete(`${baseURL}/${resource}/${id}`);
    return await response.data;
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    throw err;
  }
};

// Handles Filter Requests
export const filteredResources = async (resource, filter) => {
  try {
    const response = await axios.post(`${baseURL}/${resource}`, filter);
    return response.data;
  } catch (err) {
    console.log(`${err}`);
    throw err;
  }
};
