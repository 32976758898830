import React, { useReducer } from 'react';
import AlertContext from './alertContext';
import alertReducer from './alertReducer';
import { SET_ALERT } from '../types';

const AlertState = (props) => {
  const initialState = {
    showAlert: false,
    message: 'HELLO WORLD',
    type: 'success',
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  // Admin Dashboard

  const setAlert = (showAlert, message, type) => {
    const alert = {
      showAlert: showAlert,
      message: message,
      type: type,
    };

    dispatch({
      type: SET_ALERT,
      payload: alert,
    });
  };

  return (
    <AlertContext.Provider
      value={{
        showAlert: state.showAlert,
        message: state.message,
        type: state.type,
        setAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
