import React, { Component } from 'react';
import Button from '@mui/material/Button';

export class Home extends Component {
  render() {
    return (
      <div>
        <Button variant="contained" href="/login">
          LOGIN
        </Button>
      </div>
    );
  }
}

export default Home;
