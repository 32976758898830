import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Portal from './pages/portal/Portal';
import AuthState from './context/auth/AuthState';
import PortalNavState from './context/portalNav/PortalNavState';
import AlertState from './context/alert/AlertState';
import setAuthToken from './utils/setAuthToken';

const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#3f3b3b',
    },
    secondary: {
      main: '#feb062',
    },
  },
});

if (localStorage.auth_token) {
  setAuthToken(localStorage.auth_token);
}

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <AlertState>
        <AuthState>
          <PortalNavState>
            <div className="App">
              <Router>
                <Switch>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <PrivateRoute exact path="/portal" component={Portal} />
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </Router>
            </div>
          </PortalNavState>
        </AuthState>
      </AlertState>
    </ThemeProvider>
  );
}

export default App;
