import React, { useContext, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import alertContext from '../../../context/alert/alertContext';
import { useForm, Form } from '../../../components/controls/useForm';
import Controls from '../../../components/controls/Controls';
import portalNavContext from '../../../context/portalNav/portalNavContext';
import { useApi } from '../../../components/api/useApi';
import { putResource } from '../../../components/api/ApiFunctions';

const EditTicket = ({
  tickets,
  setTickets,
  users,
  projects,
  contacts,
  focusedTicket,
  setFocusedTicket,
}) => {
  const { setAlert } = useContext(alertContext);
  const { loadContent } = useContext(portalNavContext);

  const [ticket, setTicketSubmissionResponse] = useApi(() =>
    putResource('tickets', values, focusedTicket._id)
  );

  const editTicket = async () => {
    setTicketSubmissionResponse();
  };

  const cancelEdit = () => {
    loadContent('ticketList');
    resetForm();
  };

  const handleSelect = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const { values, resetForm, handleInputChange, setValues } = useForm(
    focusedTicket,
    false
  );

  useEffect(() => {
    var newTicketList = [];
    if (ticket.error === null && !ticket.isFetching && ticket.isSuccess) {
      const filteredTickets = tickets.filter(
        (ticket) => ticket._id !== focusedTicket._id
      );

      filteredTickets.map((ticket) => newTicketList.push(ticket));
      newTicketList.push(values);

      setTickets(newTicketList);
      setFocusedTicket(values);
      loadContent('viewTicket');
      resetForm();
      setAlert(true, 'Ticket Saved', 'success');
    } else if (ticket.error !== null) {
      setAlert(true, 'Server Error Ticket', 'error');
    }
    //eslint-disable-next-line
  }, [ticket]);

  if (values !== null) {
    return (
      <>
        <Paper elevation={4} sx={{ padding: '40px' }}>
          <Form onSubmit={editTicket}>
            <Grid container>
              <Grid item>
                <Typography
                  variant="h4"
                  component="h2"
                  gutterBottoms
                  sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
                >
                  Edit Ticket
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} flexDirection="row">
              <Grid item xs={12}>
                <Controls.Input
                  label="Overview"
                  name="ticketOverview"
                  value={values.ticketOverview}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                  color="info"
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  label="Details"
                  name="ticketDetails"
                  value={values.ticketDetails}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={8}
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                  color="info"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" sx={{ minWidth: '98%' }}>
                  <InputLabel>Customer</InputLabel>
                  <Select
                    name="ticketCustomerId"
                    value={values.ticketCustomerId}
                    onChange={handleSelect}
                    options={contacts}
                    sx={{ minWidth: '200px' }}
                  >
                    <MenuItem key="ALL" value=""></MenuItem>
                    {contacts !== null &&
                      contacts.map((contact) => (
                        <MenuItem key={contact._id} value={contact._id}>
                          {contact.firstName + ' ' + contact.lastName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" sx={{ minWidth: '100%' }}>
                  <InputLabel>Project</InputLabel>
                  <Select
                    name="ticketProjectId"
                    value={values.ticketProjectId}
                    onChange={handleSelect}
                    options={projects}
                  >
                    <MenuItem key="ALL" value=""></MenuItem>
                    {projects !== null &&
                      projects.map((project) => (
                        <MenuItem key={project._id} value={project._id}>
                          {project.projectName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" sx={{ minWidth: '98%' }}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="ticketCategory"
                    value={values.ticketCategory}
                    onChange={handleSelect}
                  >
                    <MenuItem key="bug" value="Bug">
                      Bug
                    </MenuItem>
                    <MenuItem key="feature" value="Feature Request">
                      Feature Request
                    </MenuItem>
                    <MenuItem key="trouble" value="Troubleshoot">
                      Troubleshoot
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                marginTop: (theme) => theme.spacing(2),
              }}
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Grid item>
                <Controls.CreateButton
                  text="Cancel"
                  color="warning"
                  onClick={cancelEdit}
                />
              </Grid>
              <Grid item>
                <Controls.CreateButton
                  text="Save"
                  color="success"
                  onClick={editTicket}
                />
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </>
    );
  } else {
    return null;
  }
};

export default EditTicket;
