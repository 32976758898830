import React, { useState } from 'react';

export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

const useStyles = {
  root: {
    '& .MuiFormControl-root': {
      width: '80%',
      margin: '8px',
    },
  },
};

export function Form({ children, ...other }) {
  return (
    <form className={useStyles.root} autoComplete="off" {...other}>
      {children}
    </form>
  );
}
