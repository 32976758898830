import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import UsersList from './users/UsersList';
import ErrorList from './errors/ErrorList';

export default function Settings() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon label tabs example"
      >
        <Tab icon={<PersonPinIcon />} label="USERS" />
        <Tab icon={<NearbyErrorIcon />} label="ERRORS" />
      </Tabs>
      {value === 0 ? <UsersList /> : null}
      {value === 1 ? <ErrorList /> : null}
    </>
  );
}
