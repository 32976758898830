import React, { useState } from 'react';
import NewContact from './NewContact';
import ViewContact from './ViewContact';
import ContactList from './ContactList';

const Contacts = () => {
  const [contactPage, setContactPage] = useState('contactList');
  const [contactsList, setContactsList] = useState(null);
  const [focusedContact, setFocusedContact] = useState(null);

  switch (contactPage) {
    default:
    case 'contactList':
      return (
        <ContactList
          setContactPage={setContactPage}
          setContactsList={setContactsList}
          contactsList={contactsList}
          setFocusedContact={setFocusedContact}
        />
      );

    case 'newContact':
      return (
        <NewContact
          setContactPage={setContactPage}
          setContactsList={setContactsList}
          contactsList={contactsList}
        />
      );
    case 'viewContact':
      return (
        <ViewContact
          setContactPage={setContactPage}
          setContactsList={setContactsList}
          contactsList={contactsList}
          focusedContact={focusedContact}
          setFocusedContact={setFocusedContact}
        />
      );
  }
};

export default Contacts;
