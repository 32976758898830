import React from 'react';
import Edit from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Divider,
} from '@mui/material';

const notesList = ({ notes }) => {
  return (
    <>
      {notes !== undefined && notes.length > 0
        ? notes.map((note) => (
            <Card elevation={3} sx={{ pb: 1, px: 1, mt: 2 }}>
              <CardHeader
                title={note.title}
                subheader={note.date}
                sx={{ color: '#feb062', fontWeight: 'bold' }}
              />
              <CardContent>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {note.body}
                </Typography>
              </CardContent>
              <CardActionArea>
                <Divider />
                <Grid container sx={{ mt: 2 }}>
                  <Grid item sm={3}>
                    <Typography
                      variant="body1"
                      color="secondary"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {note.author}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={9}
                    sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <DeleteForeverIcon color="error" />
                    <Edit color="warning" />
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          ))
        : null}
    </>
  );
};

export default notesList;
