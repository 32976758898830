// import Checkbox from './forms/Checkbox';
import DateInput from './forms/DateInput';
import Input from './forms/Input';
// import RadioGroup from './forms/RadioGroup';
// import Select from './forms/Select';
import CreateButton from './CreateButton';
import NewAlert from './NewAlert';

const Controls = {
  Input,
  // RadioGroup,
  // Select,
  // Checkbox,
  DateInput,
  CreateButton,
  NewAlert,
};

export default Controls;
