import { SET_MODULE, SET_CONTENT } from '../types';

const portalNavReducer = (state, action) => {
  switch (action.type) {
    case SET_MODULE:
      return {
        ...state,
        module: action.payload.module,
        content: action.payload.content,
      };
    case SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };
    default:
      return state;
  }
};

export default portalNavReducer;
