import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Grid,
  Button,
  Paper,
  Card,
  CardContent,
  Container,
} from '@mui/material';
import { useForm } from '../../../components/controls/useForm';
import { useApi } from '../../../components/api/useApi';
import {
  putResource,
  deleteResource,
} from '../../../components/api/ApiFunctions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const ViewContact = ({
  setContactPage,
  setContactsList,
  contactsList,
  focusedContact,
  setFocusedContact,
}) => {
  const [editMode, setEditMode] = useState(true);
  const { values, errors, resetForm, handleInputChange } = useForm(
    focusedContact,
    false
  );

  const [editedContact, setContactEditedResponse] = useApi(() =>
    putResource('contacts', values, focusedContact._id)
  );
  const [deletedContact, setDeleteContact] = useApi(() =>
    deleteResource('contacts', focusedContact._id)
  );

  const cancelEdit = () => {
    toggleEdit();
    resetForm();
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const updateContact = () => {
    toggleEdit();
    setContactEditedResponse();
    var updatedContactList = [];
    const oldContactsList = contactsList.filter(
      (contact) => contact._id !== focusedContact._id
    );

    oldContactsList.map((contact) => updatedContactList.push(contact));
    updatedContactList.push(values);
  };

  const deleteContact = () => {
    toggleEdit();
    var updatedContactList = [];
    setDeleteContact();
    const oldContactsList = contactsList.filter(
      (contact) => contact._id !== focusedContact._id
    );

    oldContactsList.map((contact) => updatedContactList.push(contact));
    setContactsList(updatedContactList);
    setContactPage('contactList');
  };

  return (
    <Paper elevation={6} sx={{ p: 3 }}>
      <Card elevation={4}>
        <CardContent>
          {values !== null ? (
            <Container>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 'bold' }}
                    color="#feb062"
                  >
                    Contact {!editMode && '- Editor'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DeleteForeverIcon
                    color="error"
                    sx={{ float: 'right', cursor: 'pointer' }}
                    onClick={deleteContact}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%' }}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                    disabled={editMode}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    name="address"
                    value={values.address}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%' }}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    name="address2"
                    value={values.address2}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                    disabled={editMode}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    name="city"
                    value={values.city}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%' }}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="State"
                    name="state"
                    value={values.state}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Zip"
                    name="zip"
                    value={values.zip}
                    onChange={handleInputChange}
                    sx={{
                      minWidth: '82%',
                      ml: { xs: 0, sm: 1 },
                      mt: { xs: 2, sm: 0 },
                    }}
                    disabled={editMode}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%' }}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Mobile"
                    name="mobile"
                    value={values.mobile}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                    disabled={editMode}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    sx={{ minWidth: '90%' }}
                    disabled={editMode}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  {!editMode ? (
                    <Button
                      color="error"
                      variant="contained"
                      onClick={cancelEdit}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => setContactPage('contactList')}
                    >
                      Back
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!editMode ? (
                    <Button
                      color="success"
                      variant="contained"
                      sx={{ float: 'right' }}
                      onClick={updateContact}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      color="success"
                      variant="contained"
                      sx={{ float: 'right' }}
                      onClick={toggleEdit}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Container>
          ) : null}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ViewContact;
