import React, { useReducer } from 'react';
import PortalNavContext from './portalNavContext';
import portalNavReducer from './portalNavReducer';
import { SET_MODULE, SET_CONTENT } from '../types';

const PortalNavState = (props) => {
  const initalState = {
    module: 'menu',
    content: 'main',
  };

  const [state, dispatch] = useReducer(portalNavReducer, initalState);

  const loadModule = (e) => {
    dispatch({
      type: SET_MODULE,
      payload: e,
    });
  };
  const loadContent = (e) => {
    dispatch({
      type: SET_CONTENT,
      payload: e,
    });
  };

  return (
    <PortalNavContext.Provider
      value={{
        module: state.module,
        content: state.content,
        loadModule,
        loadContent,
      }}
    >
      {props.children}
    </PortalNavContext.Provider>
  );
};

export default PortalNavState;
