import React, { useContext } from 'react';
import Contacts from './contacts/Contacts';
import Notes from './notes/Notes';
import Projects from './projects/Projects';
import Settings from './settings/Settings';
import Tasks from './tasks/Tasks';
import Tickets from './tickets/Tickets';
import Users from './users/Users';
import Dashboards from './dashboard/Dashboards';
import PortalNavContext from '../../context/portalNav/portalNavContext';
import Menu from '../../pages/menu/MainMenu';

const PortalContent = () => {
  const { module } = useContext(PortalNavContext);
  switch (module) {
    default:
    case 'dashboard':
      return <Dashboards />;
    case 'contacts':
      return <Contacts />;
    case 'notes':
      return <Notes />;
    case 'projects':
      return <Projects />;
    case 'settings':
      return <Settings />;
    case 'tasks':
      return <Tasks />;
    case 'tickets':
      return <Tickets />;
    case 'users':
      return <Users />;
    case 'menu':
      return <Menu />;
  }
};

export default PortalContent;
