import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Paper, Toolbar } from '@mui/material';
import { useForm, Form } from '../../../../components/controls/useForm';
import Controls from '../../../../components/controls/Controls';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import portalNavContext from '../../../../context/portalNav/portalNavContext';
import { useApi } from '../../../../components/api/useApi';
import {
  putResource,
  deleteResource,
} from '../../../../components/api/ApiFunctions';
import alertContext from '../../../../context/alert/alertContext';
import CreateSteps from './CreateSteps';
import StepList from './StepList';
import EditStep from './EditStep';

const EditProjectTask = ({ focusedTask, setFocusedTask }) => {
  const { loadContent } = useContext(portalNavContext);
  const { setAlert } = useContext(alertContext);
  const [projectTaskPage, setProjectTaskPage] = useState('list');
  const [editMode, setEditMode] = useState(true); //True to disable inputs.. Conditions are opposite
  const [focusedStep, setFocusedStep] = useState(null);

  const [editedTask, setTaskSubmissionResponse] = useApi(() =>
    putResource('tasks', values, focusedTask._id)
  );
  const [deletedTask, setTaskDeletionResponse] = useApi(() =>
    deleteResource('tasks', focusedTask._id)
  );

  const handleCompletionChange = (e) => {
    console.log(e.target.value);
    if (e.target.value !== null) {
      setValues({ ...values, active: false, completionDate: e.target.value });
    }
    if (e.target.value === null) {
      setValues({ ...values, active: true, completionDate: e.target.value });
    }
  };

  useEffect(() => {
    if (
      editedTask.error === null &&
      !editedTask.isFetching &&
      editedTask.isSuccess
    ) {
      // loadContent('viewProject');
      // resetForm();
      setAlert(true, 'Task Edited', 'success');
    } else if (editedTask.error !== null) {
      setAlert(true, 'Server Error Task EDIT', 'error');
    }
    // eslint-disable-next-line
  }, [editedTask]);

  useEffect(() => {
    if (
      deletedTask.error === null &&
      !deletedTask.isFetching &&
      deletedTask.isSuccess
    ) {
      loadContent('viewProject');
      resetForm();
      setAlert(true, 'Task Deleted', 'success');
    } else if (deletedTask.error !== null) {
      setAlert(true, 'Server Error Task Delete', 'error');
    }
    // eslint-disable-next-line
  }, [deletedTask]);

  const cancelEdit = () => {
    window.scroll(0, 0);
    loadContent('viewProject');
    resetForm();
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const updateTask = () => {
    setTaskSubmissionResponse();
    setEditMode(!editMode);
  };

  const { values, setValues, errors, resetForm, handleInputChange } = useForm(
    focusedTask,
    false
  );

  const goToTop = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#top'
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    const anchor = document.querySelector('#top');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <Paper elevation={4} sx={{ padding: '40px' }}>
        <Toolbar id="top" />
        {focusedTask !== null ? (
          <Form>
            <Grid container>
              <Grid item>
                <Typography
                  variant="h4"
                  component="h2"
                  gutterBottom
                  sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
                >
                  <AccountTreeIcon
                    sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                  />{' '}
                  Edit Project Task
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} flexDirection="row">
              <Grid item xs={12}>
                <Controls.Input
                  label="Task"
                  name="task"
                  value={values.task}
                  onChange={handleInputChange}
                  error={errors.task}
                  fullWidth
                  disabled={editMode}
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                  color="info"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} flexDirection="row">
              <Grid item xs={12}>
                <Controls.Input
                  label="Module"
                  name="taskCategory"
                  value={values.taskCategory}
                  onChange={handleInputChange}
                  error={errors.taskCategory}
                  fullWidth
                  disabled={editMode}
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                  color="info"
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} flexDirection="row">
              <Grid item xs={12}>
                <Controls.Input
                  label="Task Details"
                  name="taskDetails"
                  value={values.taskDetails}
                  onChange={handleInputChange}
                  error={errors.taskDetails}
                  multiline
                  rows={4}
                  disabled={editMode}
                  fullWidth
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container flexDirection="row" sx={{ mt: '16px' }}>
              <Grid item xs={12} md={4}>
                <Controls.DateInput
                  label="Project Start"
                  name="startDate"
                  disabled={editMode}
                  value={values.startDate}
                  onChange={handleInputChange}
                  error={errors.startDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ pl: { xs: 0, sm: '8px' }, mt: { xs: '8px', sm: '0' } }}
              >
                <Controls.DateInput
                  label="Due Date"
                  name="dueDate"
                  disabled={editMode}
                  value={values.dueDate}
                  onChange={handleInputChange}
                  error={errors.dueDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ pl: { xs: 0, sm: '8px' }, mt: { xs: '8px', sm: '0' } }}
              >
                <Controls.DateInput
                  label="Completion Date"
                  name="completionDate"
                  disabled={editMode}
                  value={values.completionDate}
                  onChange={handleCompletionChange}
                  error={errors.completionDate}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                marginTop: (theme) => theme.spacing(2),
              }}
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
                sm={2}
                sx={{ my: { xs: '8px', sm: 0 }, ml: { xs: 0, sm: 1 } }}
              >
                {editMode ? (
                  <Controls.CreateButton
                    text="Delete"
                    color="error"
                    onClick={() => setTaskDeletionResponse()}
                    sx={{ minWidth: '100%' }}
                  />
                ) : (
                  <Controls.CreateButton
                    text="Cancel"
                    color="error"
                    onClick={toggleEdit}
                    sx={{ minWidth: '100%' }}
                  />
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={2}
                sx={{ my: { xs: '8px', sm: 0 }, ml: { xs: 0, sm: 1 } }}
              >
                {!editMode ? (
                  <Controls.CreateButton
                    text="Update"
                    color="success"
                    onClick={updateTask}
                    sx={{ minWidth: '100%' }}
                  />
                ) : (
                  <Controls.CreateButton
                    text="Edit"
                    color="success"
                    onClick={toggleEdit}
                    sx={{ minWidth: '100%' }}
                  />
                )}
              </Grid>
              {editMode && (
                <Grid
                  item
                  xs={12}
                  sm={2}
                  sx={{ my: { xs: '8px', sm: 0 }, ml: { xs: 0, sm: 1 } }}
                >
                  <Controls.CreateButton
                    text="Back"
                    color="warning"
                    onClick={cancelEdit}
                    sx={{ minWidth: '100%' }}
                  />
                </Grid>
              )}
            </Grid>
          </Form>
        ) : null}
      </Paper>
      {projectTaskPage === 'new' ? (
        <CreateSteps
          setProjectTaskPage={setProjectTaskPage}
          focusedTask={focusedTask}
          setFocusedTask={setFocusedTask}
          setValues={setValues}
          existingValues={values}
        />
      ) : null}
      {projectTaskPage === 'edit' ? (
        <EditStep
          setProjectTaskPage={setProjectTaskPage}
          focusedTask={focusedTask}
          setFocusedTask={setFocusedTask}
          setValues={setValues}
          existingValues={values}
          focusedStep={focusedStep}
        />
      ) : null}
      {projectTaskPage === 'list' ? (
        <StepList
          setProjectTaskPage={setProjectTaskPage}
          focusedTask={focusedTask}
          setFocusedTask={setFocusedTask}
          setValues={setValues}
          values={values}
          setFocusedStep={setFocusedStep}
        />
      ) : null}
    </div>
  );
};

export default EditProjectTask;
