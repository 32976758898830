import React, { useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import DesktopPortal from './DesktopPortal';
import MobilePortal from './MobilePortal';
import Controls from '../../components/controls/Controls';
import AuthContext from '../../context/auth/authContext';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://bizappsservices.com">
        BizApps Services
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Portal() {
  const { loadUser } = useContext(AuthContext);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <DesktopPortal Copyright={Copyright} />
      {/* <MobilePortal /> */}
      <Controls.NewAlert />
    </div>
  );
}

export default Portal;
