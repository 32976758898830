import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useForm, Form } from '../../../../components/controls/useForm';
import Controls from '../../../../components/controls/Controls';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { putResource } from '../../../../components/api/ApiFunctions';

const CreateSteps = ({
  setProjectTaskPage,
  focusedTask,
  setFocusedTask,
  existingValues,
  setValues,
}) => {
  const initialFValues = {
    _id: Date.now(),
    step: '',
    active: true,
    createdAt: Date.now(),
  };

  const createStep = async () => {
    var stepsList = [];
    if (focusedTask.steps.length > 0) {
      focusedTask.steps.map((step) => stepsList.push(step));
    }
    stepsList.push(values);

    const updatedTask = {
      _id: focusedTask._id,
      steps: stepsList,
    };
    setFocusedTask({ ...focusedTask, steps: stepsList });
    setValues({ ...existingValues, steps: stepsList });
    putResource('tasks', updatedTask, focusedTask._id);
    setProjectTaskPage('list');
  };

  const cancelCreate = () => {
    setProjectTaskPage('list');
    resetForm();
  };

  const { values, errors, resetForm, handleInputChange } = useForm(
    initialFValues,
    false
  );

  return (
    <div>
      <Paper elevation={4} sx={{ padding: '40px' }}>
        <Form onSubmit={createStep}>
          <Grid container>
            <Grid item>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
              >
                <AccountTreeIcon
                  sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                />{' '}
                Create New Task Steps
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} flexDirection="row">
            <Grid item xs={12}>
              <Controls.Input
                label="Step"
                name="step"
                value={values.step}
                onChange={handleInputChange}
                error={errors.step}
                fullWidth
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
                color="info"
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              marginTop: (theme) => theme.spacing(2),
            }}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Grid item>
              <Controls.CreateButton
                text="Cancel"
                color="warning"
                onClick={cancelCreate}
              />
            </Grid>
            <Grid item>
              <Controls.CreateButton
                text="Create"
                color="success"
                onClick={createStep}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </div>
  );
};

export default CreateSteps;
