const mmddyy = (date) => {
  const formDate = (string) => {
    if (string === null) {
      return "Pending";
    } else {
      var d = new Date(string);
      var mm = ("0" + (d.getMonth() + 1)).slice(-2);
      var dd = ("0" + d.getDate()).slice(-2);
      var yy = d.getFullYear();

      var myDateString = mm + "-" + dd + "-" + yy; //(US)

      return myDateString;
    }
  };
  return formDate(date);
};

export default mmddyy;
