import React, { useContext, useEffect } from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useForm, Form } from '../../../components/controls/useForm';
import Controls from '../../../components/controls/Controls';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import portalNavContext from '../../../context/portalNav/portalNavContext';
import alertContext from '../../../context/alert/alertContext';
import { useApi } from '../../../components/api/useApi';
import { putResource } from '../../../components/api/ApiFunctions';

const EditProject = ({ focusedProject, projects, setProjects }) => {
  const { loadContent } = useContext(portalNavContext);
  const { setAlert } = useContext(alertContext);

  const [editedProject, setProjectEditsResponse] = useApi(() =>
    putResource('projects', values, values._id)
  );

  const updateProject = async () => {
    setProjectEditsResponse();
  };

  useEffect(() => {
    var updatedProjectList = [];
    if (
      editedProject.error === null &&
      !editedProject.isFetching &&
      editedProject.isSuccess
    ) {
      projects.map((project) => {
        if (project._id !== values._id) {
          updatedProjectList.push(project);
        }
        return null;
      });

      updatedProjectList.push(values);

      setProjects(updatedProjectList);
      loadContent('projectListing');
      resetForm();
      setAlert(true, 'Project Updated', 'success');
    }
    // eslint-disable-next-line
  }, [editedProject]);

  const cancelUpdate = () => {
    loadContent('projectListing');
    resetForm();
  };

  const { values, setValues, errors, resetForm, handleInputChange } = useForm(
    focusedProject,
    false
  );

  const handleCompletion = (e) => {
    if (values.completion_date === null) {
      setValues({ ...values, completion_date: e.target.value, active: false });
    } else {
      setValues({ ...values, completion_date: e.target.value, active: true });
    }
  };

  return (
    <div>
      <Paper elevation={4} sx={{ padding: '40px' }}>
        {focusedProject && (
          <Form onSubmit={updateProject}>
            <Grid container>
              <Grid item>
                <Typography variant="h4" component="h2" gutterBottom>
                  <AccountTreeIcon /> Edit Project
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} flexDirection="row">
              <Grid item xs={12}>
                <Controls.Input
                  label="Project Name"
                  name="projectName"
                  value={values.projectName}
                  onChange={handleInputChange}
                  error={errors.projectName}
                  fullWidth
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                  color="info"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Project Customer"
                    name="customer"
                    value={values.customer}
                    onChange={handleInputChange}
                    error={errors.customer}
                    fullWidth
                    sx={{
                      marginRight: '16px',
                      marginTop: '8px',
                      marginBottom: '8px',
                      minWidth: '100%',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} flexDirection="row">
              <Grid item xs={12}>
                <Controls.Input
                  label="Project Description"
                  name="projectDescription"
                  value={values.projectDescription}
                  onChange={handleInputChange}
                  error={errors.projectDescription}
                  multiline
                  rows={6}
                  fullWidth
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} flexDirection="row" sx={{ mt: '16px' }}>
              <Grid item xs={12} md={4}>
                <Controls.DateInput
                  label="Project Start"
                  name="start_date"
                  value={values.start_date}
                  onChange={handleInputChange}
                  error={errors.start_date}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ pl: { xs: 0, sm: '8px' }, mt: { xs: '8px', sm: '0' } }}
              >
                <Controls.DateInput
                  label="Project Due"
                  name="due_date"
                  value={values.due_date}
                  onChange={handleInputChange}
                  error={errors.due_date}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ pl: { xs: 0, sm: '8px' }, mt: { xs: '8px', sm: '0' } }}
              >
                <Controls.DateInput
                  label="Completion"
                  name="completion_date"
                  value={values.completion_date}
                  onChange={handleCompletion}
                  error={errors.completion_date}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                marginTop: (theme) => theme.spacing(3),
              }}
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Grid item>
                <Controls.CreateButton
                  text="Cancel"
                  color="warning"
                  onClick={cancelUpdate}
                />
              </Grid>
              <Grid item>
                <Controls.CreateButton
                  text="Update"
                  color="success"
                  onClick={updateProject}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Paper>
    </div>
  );
};

export default EditProject;
