import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
} from '@mui/material';
import mmddyy from '../../../../utils/mmddyy';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import { putResource } from '../../../../components/api/ApiFunctions';

const StepList = ({
  focusedTask,
  setFocusedTask,
  setProjectTaskPage,
  values,
  setValues,
  setFocusedStep,
}) => {
  const [activeSteps, setActiveSteps] = useState(null);
  const [completedSteps, setCompletedSteps] = useState(null);

  useEffect(() => {
    if (focusedTask !== null) {
      const activeStepsList = focusedTask.steps.filter(
        (step) => step.active === true
      );
      const completedStepsList = focusedTask.steps.filter(
        (step) => step.active === false
      );
      setActiveSteps(activeStepsList);
      setCompletedSteps(completedStepsList);
    }
  }, [focusedTask]);

  const deleteStep = (id) => {
    const updatedList = focusedTask.steps.filter((step) => step._id !== id);

    setFocusedTask({ ...focusedTask, steps: updatedList });
    setValues({ ...values, steps: updatedList });

    const updatedStepsList = {
      _id: focusedTask._id,
      steps: updatedList,
    };

    putResource('tasks', updatedStepsList, focusedTask._id);
  };

  const handleCheckBox = (updateStep) => {
    var stepsList = [];
    if (updateStep.active) {
      const otherSteps = focusedTask.steps.filter(
        (step) => step._id !== updateStep._id
      );

      otherSteps.map((step) => stepsList.push(step));

      const updatedStep = {
        _id: updateStep._id,
        step: updateStep.step,
        active: false,
        createdAt: updateStep.createdAt,
      };
      stepsList.push(updatedStep);
    } else {
      const otherSteps = focusedTask.steps.filter(
        (step) => step._id !== updateStep._id
      );

      otherSteps.map((step) => stepsList.push(step));

      const updatedStep = {
        _id: updateStep._id,
        step: updateStep.step,
        active: true,
        createdAt: updateStep.createdAt,
      };
      stepsList.push(updatedStep);
    }
    const updatedTask = {
      _id: focusedTask._id,
      steps: stepsList,
    };
    setFocusedTask({ ...focusedTask, steps: stepsList });
    putResource('tasks', updatedTask, focusedTask._id);
  };

  const editStep = (step) => {
    setFocusedStep(step);
    setProjectTaskPage('edit');
  };

  return (
    <Paper elevation={4} sx={{ mt: 2, p: 3 }}>
      <Grid container>
        <Grid item xs={12}>
          <IconButton
            onClick={() => setProjectTaskPage('new')}
            sx={{ float: 'right' }}
          >
            <AddCircleIcon color="success" />
          </IconButton>
        </Grid>
      </Grid>
      <Card elevation={4} sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6"> Active</Typography>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: '#575151' }}>
                <TableCell sx={{ color: 'white' }}>Status</TableCell>
                <TableCell sx={{ color: 'white' }}>Task Step</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeSteps !== null && activeSteps.length > 0
                ? activeSteps.map((step) => (
                    <TableRow key={step._id}>
                      <TableCell size="small">
                        <Checkbox
                          checked={step.active === true ? false : true}
                          onClick={() => handleCheckBox(step)}
                        />
                      </TableCell>
                      <TableCell size="medium">{step.step}</TableCell>
                      <TableCell size="small">
                        <Grid container sx={{ justifyContent: 'end' }}>
                          <Grid item>
                            <IconButton onClick={() => editStep(step)}>
                              <EditIcon color="warning" />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={() => deleteStep(step._id)}>
                              <HighlightOffIcon color="error" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }} elevation={4}>
        <CardContent>
          <Typography variant="h6"> Completed</Typography>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: '#575151' }}>
                <TableCell sx={{ color: 'white' }}>Status</TableCell>
                <TableCell sx={{ color: 'white' }}>Task Step</TableCell>
                <TableCell sx={{ color: 'white' }}>Created Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedSteps !== null
                ? completedSteps.map((step) => (
                    <TableRow key={step._id}>
                      <TableCell size="small">
                        <Checkbox
                          checked={step.active === true ? false : true}
                          onClick={() => handleCheckBox(step)}
                        />
                      </TableCell>
                      <TableCell size="medium">{step.step}</TableCell>
                      <TableCell size="small">
                        {mmddyy(step.createdAt)}
                      </TableCell>
                      <TableCell size="small">
                        <Grid container sx={{ justifyContent: 'end' }}>
                          <Grid item>
                            <IconButton onClick={() => editStep(step)}>
                              <EditIcon color="warning" />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={() => deleteStep(step._id)}>
                              <HighlightOffIcon color="error" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default StepList;
