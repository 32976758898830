import React, { useEffect } from 'react';
import { useApi } from '../../../components/api/useApi';
import { fetchResources } from '../../../components/api/ApiFunctions';
import {
  Container,
  Card,
  CardContent,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Grid,
} from '@mui/material';

const ContactList = ({
  setContactPage,
  setContactsList,
  contactsList,
  setFocusedContact,
}) => {
  const [contactData, setContactListResponse] = useApi(() =>
    fetchResources('contacts')
  );

  useEffect(() => {
    setContactListResponse();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      contactData.error === null &&
      contactData.isSuccess === true &&
      contactData.isFetching === false
    ) {
      setContactsList(contactData.data);
    }

    //eslint-disable-next-line
  }, [contactData]);

  const viewContact = (contact) => {
    setFocusedContact(contact);
    setContactPage('viewContact');
  };
  return (
    <Container>
      <Paper elevation={6} sx={{ p: 3, minHeight: '500px' }}>
        <Card elevation={4} sx={{ minHeight: '450px' }}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" color="#feb062">
                  Contacts
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="success"
                  onClick={() => setContactPage('newContact')}
                  variant="contained"
                  size="small"
                  sx={{ float: 'right', mr: 3 }}
                >
                  New
                </Button>
              </Grid>
            </Grid>
            <Table size="small" sx={{ mt: 1 }}>
              <TableHead sx={{ background: '#575151', color: '#feb062' }}>
                <TableRow>
                  <TableCell sx={{ textAlign: 'left', color: '#feb062' }}>
                    First
                  </TableCell>
                  <TableCell sx={{ textAlign: 'left', color: '#feb062' }}>
                    Last
                  </TableCell>
                  <TableCell
                    sx={{
                      display: { xs: 'none', sm: 'table-cell' },
                      color: '#feb062',
                    }}
                  >
                    Phone
                  </TableCell>
                  <TableCell
                    sx={{
                      display: { xs: 'none', sm: 'table-cell' },
                      color: '#feb062',
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell sx={{ color: '#feb062' }}>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactsList !== null
                  ? contactsList.map((contact) => (
                      <TableRow
                        key={contact._id}
                        hover
                        onClick={() => viewContact(contact)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{contact.firstName}</TableCell>
                        <TableCell>{contact.lastName}</TableCell>
                        <TableCell
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          {contact.phone}
                        </TableCell>
                        <TableCell
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          {contact.mobile}
                        </TableCell>
                        <TableCell>{contact.email}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default ContactList;
