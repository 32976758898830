import React from 'react';
import { Box, Paper, Typography, Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import mmddyy from '../../../../utils/mmddyy';
import { putResource } from '../../../../components/api/ApiFunctions';

const ProjectNotesList = ({
  focusedProject,
  setFocusedProject,
  projects,
  setProjects,
  setShowEditNote,
  setNoteToEdit,
}) => {
  const updateProjects = (updatedProject) => {
    var updatedProjectsList = [];

    const filteredProjects = projects.filter(
      (project) => project._id !== focusedProject._id
    );

    filteredProjects.map((project) => updatedProjectsList.push(project));

    updatedProjectsList.push(updatedProject);

    setProjects(updatedProjectsList);
  };

  const deleteNote = async (id) => {
    var notesList = [];
    const filteredList = focusedProject.notes.filter((note) => note._id !== id);
    filteredList.map((note) => notesList.push(note));

    setFocusedProject({ ...focusedProject, notes: notesList });

    const updatedNotesList = {
      _id: focusedProject._id,
      notes: notesList,
    };

    await putResource('projects', updatedNotesList, focusedProject._id)
      .then((response) => {
        updateProjects(response[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editNote = (note) => {
    setNoteToEdit(note);
    setShowEditNote(true);
  };

  return (
    <>
      {focusedProject !== undefined && focusedProject.notes.length > 0
        ? focusedProject.notes.map((note) => (
            <Box sx={{ width: '100%', mt: 2 }}>
              <Paper
                sx={{ width: '100%', mb: 2, padding: ' 32px 32px 8px 32px' }}
                elevation={6}
              >
                <Typography variant="h6">{note.title}</Typography>
                <Typography variant="body2">{note.projectNote}</Typography>
                <Grid container sx={{ mt: 2 }}>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {mmddyy(note.createdAt)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <IconButton>
                      <EditIcon
                        color="warning"
                        onClick={() => editNote(note)}
                      />
                    </IconButton>
                    <IconButton>
                      <DeleteForeverIcon
                        color="error"
                        onClick={() => deleteNote(note._id)}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ))
        : null}
    </>
  );
};

export default ProjectNotesList;
