import React, { useContext, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import AuthContext from '../../../../context/auth/authContext';

const CreateNote = () => {
  const { user } = useContext(AuthContext);
  const [newNote, setNewNote] = useState({
    _id: Date.now(),
    title: '',
    date: '',
    body: '',
    author: user.First_Name + ' ' + user.Last_Name,
  });

  return (
    <Card elevation={8} sx={{ p: 1 }}>
      <CardContent>
        <Typography variant="h5" color="primary">
          Create Note
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CreateNote;
