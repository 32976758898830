import React from 'react';
import Button from '@mui/material/Button';

const useStyles = (theme) => ({
  root: {
    margin: '4px',
    textTransform: 'none',
  },
});

export default function CreateButton({
  text,
  size,
  color,
  variant,
  onClick,
  sx,
  ...other
}) {
  const classes = useStyles();

  return (
    <Button
      variant={variant || 'contained'}
      size={size || 'large'}
      color={color || 'primary'}
      onClick={onClick}
      {...other}
      sx={sx || classes.root}
    >
      {text}
    </Button>
  );
}
