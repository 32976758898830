import React, { useReducer, useContext } from 'react';
import AuthContext from './authContext';
import axios from 'axios';
import authReducer from './authReducer';
import AlertContext from '../alert/alertContext';
import setAuthToken from '../../utils/setAuthToken';
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from '../types';

const AuthState = (props) => {
  const { setAlert } = useContext(AlertContext);
  const initialState = {
    token: localStorage.getItem('auth_token'),
    user: null,
    isAuthenticated: false,
    registration_complete: null,
    password_validated: true,
    loading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const updateAuthUser = (user) => {
    dispatch({
      type: USER_LOADED,
      payload: user,
    });
  };

  // LOAD USER
  const loadUser = async () => {
    if (localStorage.auth_token) {
      setAuthToken(localStorage.auth_token);
    }
    try {
      const res = await axios.get('/api/auth');
      dispatch({ type: USER_LOADED, payload: res.data });
    } catch (err) {
      setAlert(true, 'Invalid Credentials', 'error');
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

  // Login User
  const loginUser = async (formData) => {
    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/auth', formData, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err,
      });
    }
  };

  // Logout
  const logoutUser = () => dispatch({ type: LOGOUT });

  // Clear Errors
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        password_validated: state.password_validated,
        loadUser,
        loginUser,
        logoutUser,
        clearErrors,
        updateAuthUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
