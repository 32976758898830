import React, { useContext, useState, useEffect } from 'react';
import PortalNavContext from '../../../context/portalNav/portalNavContext';
import { useApi } from '../../../components/api/useApi';
import { fetchResources } from '../../../components/api/ApiFunctions';
import TicketList from './TicketList';
import CreateTicket from './CreateTicket';
import ViewTicket from './ViewTicket';
import EditTicket from './EditTicket';

const Tickets = () => {
  const { content } = useContext(PortalNavContext);
  const [focusedTicket, setFocusedTicket] = useState(null);
  const [projects, setProjects] = useState(null);
  const [users, setUsers] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [projectData, setProjectDataResponse] = useApi(() =>
    fetchResources('projects')
  );
  const [userData, setUserDataResponse] = useApi(() =>
    fetchResources('auth/all')
  );
  const [ticketData, setTicketDataResponse] = useApi(() =>
    fetchResources('tickets')
  );
  const [contactData, setContactDataResponse] = useApi(() =>
    fetchResources('contacts')
  );

  useEffect(() => {
    setProjectDataResponse();
    setTicketDataResponse();
    setUserDataResponse();
    setContactDataResponse();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      projectData.error === null &&
      projectData.isSuccess === true &&
      projectData.isFetching === false
    ) {
      setProjects(projectData.data);
    }

    //eslint-disable-next-line
  }, [projectData]);

  useEffect(() => {
    if (
      userData.error === null &&
      userData.isSuccess === true &&
      userData.isFetching === false
    ) {
      setUsers(userData.data);
    }

    //eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (
      ticketData.error === null &&
      ticketData.isSuccess === true &&
      ticketData.isFetching === false
    ) {
      setTickets(ticketData.data);
    }

    //eslint-disable-next-line
  }, [ticketData]);

  useEffect(() => {
    if (
      contactData.error === null &&
      contactData.isSuccess === true &&
      contactData.isFetching === false
    ) {
      setContacts(contactData.data);
    }

    //eslint-disable-next-line
  }, [contactData]);

  if (tickets !== null) {
    switch (content) {
      default:
      case 'ticketList':
        return (
          <TicketList
            setFocusedTicket={setFocusedTicket}
            tickets={tickets}
            users={users}
            projects={projects}
            contacts={contacts}
          />
        );
      case 'createTicket':
        return (
          <CreateTicket
            tickets={tickets}
            setTickets={setTickets}
            projects={projects}
            contacts={contacts}
          />
        );
      case 'viewTicket':
        return (
          <ViewTicket
            tickets={tickets}
            setTickets={setTickets}
            users={users}
            projects={projects}
            contacts={contacts}
            focusedTicket={focusedTicket}
            setFocusedTicket={setFocusedTicket}
          />
        );
      case 'editTicket':
        return (
          <EditTicket
            tickets={tickets}
            setTickets={setTickets}
            users={users}
            projects={projects}
            contacts={contacts}
            focusedTicket={focusedTicket}
            setFocusedTicket={setFocusedTicket}
          />
        );
    }
  } else {
    return <div>LOADING</div>;
  }
};

export default Tickets;
