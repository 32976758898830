import React, { useContext } from 'react';
import {
  Container,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import PortalNavContext from '../../context/portalNav/portalNavContext';
import AuthContext from '../../context/auth/authContext';

const MainMenu = () => {
  const { loadModule } = useContext(PortalNavContext);
  const { logoutUser } = useContext(AuthContext);

  return (
    <Container
      sx={{
        mt: { xs: '20%', lg: '5%' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Paper elevation={8} sx={{ p: { xs: 3, lg: 6 } }}>
        <Grid container>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: 'projects', content: 'projectList' })
              }
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DashboardIcon sx={{ color: '#feb062' }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Projects
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: 'tickets', content: 'ticketList' })
              }
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <NoteAddIcon sx={{ color: '#feb062' }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Tickets
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: 'contacts', content: 'contactList' })
              }
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <PeopleIcon sx={{ color: '#feb062' }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Contacts
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: 'notes', content: 'noteList' })
              }
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <LayersIcon sx={{ color: '#feb062' }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Knowledge
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: 'settings', content: 'main' })
              }
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <SettingsIcon sx={{ color: '#feb062' }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Settings
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card elevation={4} onClick={() => logoutUser()}>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <LogoutIcon sx={{ color: '#feb062' }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Logout
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default MainMenu;
