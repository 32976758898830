import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Controls from '../../components/controls/Controls';
import AlertContext from '../../context/alert/alertContext';
import { useHistory } from 'react-router-dom';

export default function Login() {
  const { loginUser, error, clearErrors, isAuthenticated, loadUser } =
    useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();

  const [user, setUser] = useState({
    Username: '',
    Password: '',
  });

  const { Username, Password } = user;

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser();
    }
    if (isAuthenticated) {
      history.push('/portal');
    }
    if (error === 'Invalid Credentials') {
      setAlert(true, 'Invalid Credentials', 'error');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated]);

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Username === '' || Password === '') {
      console.log('ERROR');
      setAlert(true, 'Please fill in all fields', 'error');
    } else {
      loginUser({
        Username,
        Password,
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 9,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{ m: 1, background: (theme) => theme.palette.primary.main }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            name="Username"
            autoComplete="email"
            autoFocus
            onChange={onChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="Password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Controls.NewAlert />
      </Box>
    </Container>
  );
}
