import React, { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  CardContent,
  Card,
  IconButton,
  Toolbar,
} from '@mui/material';
import mmddyy from '../../../utils/mmddyy';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import portalNavContext from '../../../context/portalNav/portalNavContext';
import { useApi } from '../../../components/api/useApi';
import {
  deleteResource,
  filteredResources,
} from '../../../components/api/ApiFunctions';
import AlertContext from '../../../context/alert/alertContext';
import ProjectTaskList from './tasks/ProjectTaskList';
import Controls from '../../../components/controls/Controls';
import Backdrop from '@mui/material/Backdrop';
import ProjectNotesList from './notes/ProjectNotesList';
import CreateProjectNote from './notes/CreateProjectNote';
import EditProjectNote from './notes/EditProjectNote';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LayersIcon from '@mui/icons-material/Layers';
import NotesIcon from '@mui/icons-material/Notes';

const ViewProject = (
  { focusedProject, setFocusedTask, projects, setProjects, setFocusedProject },
  props
) => {
  const { loadContent } = useContext(portalNavContext);
  const { setAlert } = useContext(AlertContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openNotStartedTasks, setOpenNotStartedTasks] = useState(null);
  const [inProgressTasks, setInProgressTasks] = useState(null);
  const [completedTasks, setCompletedTasks] = useState(null);
  const [showCreateNote, setShowCreateNote] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);
  const [deleteProjectResponse, setDeleteProjectResponse] = useApi(() =>
    deleteResource('projects', focusedProject._id)
  );

  const [openTasksResponse, setOpenTasksResponse] = useApi(() =>
    filteredResources('tasks/filtered', {
      filters: {
        active: true,
        startDate: null,
        taskProjectId: focusedProject._id,
      },
    })
  );

  const goToTop = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#top'
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };
  const goToTasks = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#task'
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };
  const goToNotes = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#note'
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const [inProgressTasksResponse, setInProgressTasksResponse] = useApi(() =>
    filteredResources('tasks/filtered', {
      filters: {
        active: true,
        startDate: { $ne: null },
        completionDate: null,
        taskProjectId: focusedProject._id,
      },
    })
  );
  const [completedTasksResponse, setCompletedTasksResponse] = useApi(() =>
    filteredResources('tasks/filtered', {
      filters: {
        active: false,
        completionDate: { $ne: null },
        taskProjectId: focusedProject._id,
      },
    })
  );

  const toggleCreateNote = () => {
    setShowCreateNote(!showCreateNote);
  };

  const confirmDeletion = () => {
    setShowDeleteConfirmation(true);
  };
  const cancelDeletion = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteProject = () => {
    openNotStartedTasks.map((task) => {
      deleteResource('tasks', task._id);
      return null;
    });
    inProgressTasks.map((task) => {
      deleteResource('tasks', task._id);
      return null;
    });
    completedTasks.map((task) => {
      deleteResource('tasks', task._id);
      return null;
    });

    const newProjectList = projects.filter(
      (project) => project._id !== focusedProject._id
    );

    setProjects(newProjectList);

    setDeleteProjectResponse();
    loadContent('projectList');
    setFocusedProject(null);
  };

  useEffect(() => {
    if (focusedProject !== null) {
      setOpenTasksResponse();
      setInProgressTasksResponse();
      setCompletedTasksResponse();
    }
    // eslint-disable-next-line
  }, [focusedProject]);

  useEffect(() => {
    if (
      completedTasksResponse.error === null &&
      !completedTasksResponse.isFetching &&
      completedTasksResponse.isSuccess
    ) {
      setCompletedTasks(completedTasksResponse.data);
    }
    // eslint-disable-next-line
  }, [completedTasksResponse]);
  useEffect(() => {
    if (
      inProgressTasksResponse.error === null &&
      !inProgressTasksResponse.isFetching &&
      inProgressTasksResponse.isSuccess
    ) {
      setInProgressTasks(inProgressTasksResponse.data);
    }
    // eslint-disable-next-line
  }, [inProgressTasksResponse]);

  useEffect(() => {
    if (
      openTasksResponse.error === null &&
      !openTasksResponse.isFetching &&
      openTasksResponse.isSuccess
    ) {
      setOpenNotStartedTasks(openTasksResponse.data);
    }
    // eslint-disable-next-line
  }, [openTasksResponse]);

  useEffect(() => {
    var newProjectList = [];
    if (
      deleteProjectResponse.error === null &&
      !deleteProjectResponse.isFetching &&
      deleteProjectResponse.isSuccess
    ) {
      projects.map((project) => {
        if (project._id !== focusedProject._id) {
          newProjectList.push(project);
        }
        return null;
      });

      setProjects(newProjectList);
      loadContent('projectListing');
      setAlert(true, 'Project Deleted', 'success');
    } else if (deleteProjectResponse.error !== null) {
      setAlert(true, 'Project Deleted error', 'error');
    }

    // eslint-disable-next-line
  }, [deleteProjectResponse]);

  useEffect(() => {
    const anchor = document.querySelector('#top');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    //eslint-disable-next-line
  }, []);

  if (focusedProject !== null) {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showDeleteConfirmation}
        >
          <Paper sx={{ minWidth: '35%', minHeight: '30%' }}>
            <Grid container>
              <Grid item sx={{ textAlign: 'center', minWidth: '100%', mt: 4 }}>
                <Typography variant="h4">Please Confirm Deletion</Typography>
                <Typography variant="h6">
                  Project & All Project Tasks will be deleted
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 3 }} justifyContent="center">
              <Grid item>
                <Controls.CreateButton
                  text="Cancel"
                  color="error"
                  onClick={cancelDeletion}
                />
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Controls.CreateButton
                  text="Confirmed"
                  color="success"
                  onClick={deleteProject}
                />
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
        <Paper
          elevation={4}
          sx={{ padding: '20px 30px 50px 30px', backgroundColor: '#575151' }}
        >
          <Grid container>
            <Grid item xs={10}>
              <ArrowBackIosNewIcon
                fontSize="medium"
                sx={{
                  color: '#feb062',
                  cursor: 'pointer',
                }}
                onClick={() => loadContent('projectList')}
              />
            </Grid>

            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              <IconButton onClick={() => loadContent('editProject')}>
                <EditIcon
                  fontSize="medium"
                  sx={{
                    color: 'orange',
                  }}
                />
              </IconButton>
              <IconButton onClick={confirmDeletion}>
                <DeleteForeverIcon
                  fontSize="medium"
                  sx={{
                    color: 'red',
                    marginLeft: '8px',
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <div id="top" />
          <Grid container flexDirection="row-reverse" sx={{ mt: 2 }}>
            <IconButton onClick={goToNotes}>
              <LayersIcon sx={{ color: '#fafaf6' }} />
            </IconButton>
            <IconButton onClick={goToTasks}>
              <NotesIcon sx={{ color: '#fafaf6' }} />
            </IconButton>
            <Typography color="white" sx={{ mt: 1 }}>
              JumpTo:
            </Typography>
          </Grid>
          <Card elevation={6} sx={{ mt: 2 }}>
            <CardContent>
              <Card elevation={4}>
                <CardContent>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={4} md={2}>
                      <Typography
                        variant="h5"
                        component="h5"
                        // gutterBottom
                        sx={{ fontWeight: 'bold' }}
                      >
                        PROJECT:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h5" component="h5" gutterBottom>
                        {focusedProject.projectName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={2}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Customer:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        {focusedProject.customer}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={2}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Start:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                        {mmddyy(focusedProject.start_date)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={2}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Due:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                        {mmddyy(focusedProject.due_date)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={2}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Completion:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                        {mmddyy(focusedProject.completion_date)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card
                elevation={4}
                sx={{ width: '100%', my: (theme) => theme.spacing(1) }}
              >
                <CardContent>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Description:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="string"
                        component="p"
                        sx={{ p: '0 8px' }}
                      >
                        {focusedProject.projectDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
          </Card>

          <>
            <Grid container>
              <Grid item xs={8}>
                <Controls.CreateButton
                  text="New Task"
                  color="success"
                  onClick={() => loadContent('createProjectTask')}
                  sx={{ mt: '16px' }}
                />
              </Grid>
              <Grid item xs={4}>
                <IconButton onClick={goToTop} sx={{ float: 'right' }}>
                  <Fab
                    sx={{ background: '#feb062', mt: '16px' }}
                    size="small"
                    aria-label="scroll back to top"
                  >
                    <KeyboardArrowUpIcon />
                  </Fab>
                </IconButton>
              </Grid>
            </Grid>
          </>
          {inProgressTasks !== null ? (
            <ProjectTaskList
              tableData={inProgressTasks}
              tableTitle="Open Tasks -  In Progress"
              setFocusedTask={setFocusedTask}
            />
          ) : (
            <div> NO InProgress </div>
          )}
          <Toolbar id="task" />
          {openNotStartedTasks !== null ? (
            <ProjectTaskList
              tableData={openNotStartedTasks}
              tableTitle="Open Task - Not Started"
              setFocusedTask={setFocusedTask}
            />
          ) : (
            <div> NO OPEN </div>
          )}
          {completedTasks !== null ? (
            <ProjectTaskList
              tableData={completedTasks}
              tableTitle="Tasks - Completed"
              setFocusedTask={setFocusedTask}
            />
          ) : (
            <div> NO Completed </div>
          )}
          {!showCreateNote && (
            <>
              <Grid container>
                <Grid item xs={8}>
                  <Controls.CreateButton
                    text="New Note"
                    color="success"
                    onClick={toggleCreateNote}
                    sx={{ mt: '16px' }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IconButton onClick={goToTop} sx={{ float: 'right' }}>
                    <Fab
                      sx={{ background: '#feb062', mt: '16px' }}
                      size="small"
                      aria-label="scroll back to top"
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          )}

          {showCreateNote && (
            <CreateProjectNote
              focusedProject={focusedProject}
              toggleCreateNote={toggleCreateNote}
              setFocusedProject={setFocusedProject}
              projects={projects}
              setProjects={setProjects}
            />
          )}
          {showEditNote && (
            <EditProjectNote
              focusedProject={focusedProject}
              toggleCreateNote={toggleCreateNote}
              setFocusedProject={setFocusedProject}
              projects={projects}
              setProjects={setProjects}
              noteToEdit={noteToEdit}
              setShowEditNote={setShowEditNote}
            />
          )}
          <Toolbar id="note" />
          {focusedProject !== null && !showEditNote ? (
            <ProjectNotesList
              setFocusedProject={setFocusedProject}
              focusedProject={focusedProject}
              setNoteToEdit={setNoteToEdit}
              projects={projects}
              setProjects={setProjects}
              setShowEditNote={setShowEditNote}
            />
          ) : null}
        </Paper>
      </div>
    );
  } else {
    return null;
  }
};

export default ViewProject;
