import React, { useContext, useState, useEffect } from 'react';
import PortalNavContext from '../../../context/portalNav/portalNavContext';
import Container from '@mui/material/Container';
import { Typography, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Controls from '../../../components/controls/Controls';
import mmddyy from '../../../utils/mmddyy';

const ProjectList = ({ setFocusedProject, projects }) => {
  const { loadContent } = useContext(PortalNavContext);
  const [openProjects, setOpenProjects] = useState(null);
  const [closedProjects, setClosedProjects] = useState(null);

  useEffect(() => {
    if (projects !== null) {
      const openProjectsList = projects.filter(
        (project) => project.active === true
      );
      const closedProjectsList = projects.filter(
        (project) => project.active === false
      );
      setOpenProjects(openProjectsList);
      setClosedProjects(closedProjectsList);
    }

    //eslint-disable-next-line
  }, [projects]);

  const useStyle = (theme) => ({
    paper: {
      padding: '16px',
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    container: {
      paddingTop: '32px',
      paddingBottom: '32px',
    },
  });

  const viewProject = (project) => {
    loadContent('viewProject');
    setFocusedProject(project);
  };

  const classes = useStyle();

  return (
    <div>
      <div />
      <Container maxWidth="lg" className={classes.container}>
        <Paper elevation={4} sx={{ mt: 2, p: 3 }}>
          <Grid container sx={{ marginBottom: (theme) => theme.spacing(2) }}>
            <Grid item xs={11}>
              <Typography variant="h5" color="#feb062">
                Projects
              </Typography>
            </Grid>
            <Grid item>
              <Controls.CreateButton
                text="New"
                color="success"
                onClick={() => loadContent('createProject')}
              />
            </Grid>
          </Grid>
          <Card elevation={4}>
            <CardContent>
              <Typography variant="h6" color="#575151" sx={{ py: 2 }}>
                Open Projects
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table
                      size="small"
                      sx={{ minWidth: 650 }}
                      aria-label="Projects List"
                    >
                      <TableHead>
                        <TableRow
                          sx={{ background: '#575151', color: '#feb062' }}
                        >
                          <TableCell
                            sx={{ textAlign: 'left', color: '#feb062' }}
                          >
                            Project
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', color: '#feb062' }}
                          >
                            Customer
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              color: '#feb062',
                            }}
                          >
                            Start Date
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              color: '#feb062',
                              display: { xs: 'none', sm: 'table-cell' },
                            }}
                          >
                            Due Date
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              color: '#feb062',
                              display: { xs: 'none', sm: 'table-cell' },
                            }}
                          >
                            Completion Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {openProjects !== null || undefined
                          ? openProjects.map((project) => (
                              <TableRow
                                key={project._id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                                hover="true"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                  onClick={() => viewProject(project)}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  {project.projectName}
                                </TableCell>
                                <TableCell align="center">
                                  {project.customer}
                                </TableCell>
                                <TableCell align="center">
                                  {mmddyy(project.start_date)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    display: { xs: 'none', sm: 'table-cell' },
                                  }}
                                >
                                  {mmddyy(project.due_date)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    display: { xs: 'none', sm: 'table-cell' },
                                  }}
                                >
                                  {mmddyy(project.completion_date)}
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" color="#575151" sx={{ py: 2 }}>
                Completed Projects
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table
                      size="small"
                      sx={{ minWidth: 650 }}
                      aria-label="Projects List"
                    >
                      <TableHead>
                        <TableRow
                          sx={{ background: '#575151', color: '#feb062' }}
                        >
                          <TableCell
                            sx={{ textAlign: 'left', color: '#feb062' }}
                          >
                            Project
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', color: '#feb062' }}
                          >
                            Customer
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              color: '#feb062',
                              display: { xs: 'none', sm: 'table-cell' },
                            }}
                          >
                            Start Date
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              color: '#feb062',
                              display: { xs: 'none', sm: 'table-cell' },
                            }}
                          >
                            Due Date
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', color: '#feb062' }}
                          >
                            Completion Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {closedProjects !== null || undefined
                          ? closedProjects.map((project) => (
                              <TableRow
                                key={project._id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                                hover="true"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                  button
                                  onClick={() => viewProject(project)}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  {project.projectName}
                                </TableCell>
                                <TableCell align="center">
                                  {project.customer}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    display: { xs: 'none', sm: 'table-cell' },
                                  }}
                                >
                                  {mmddyy(project.start_date)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    display: { xs: 'none', sm: 'table-cell' },
                                  }}
                                >
                                  {mmddyy(project.due_date)}
                                </TableCell>
                                <TableCell align="center">
                                  {mmddyy(project.completion_date)}
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Container>
    </div>
  );
};

export default ProjectList;
