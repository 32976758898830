import React, { useContext, useEffect } from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useForm, Form } from '../../../components/controls/useForm';
import Controls from '../../../components/controls/Controls';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import portalNavContext from '../../../context/portalNav/portalNavContext';
import { useApi } from '../../../components/api/useApi';
import { postResource } from '../../../components/api/ApiFunctions';
import alertContext from '../../../context/alert/alertContext';

const CreateProject = ({ projects, setProjects }) => {
  const { loadContent } = useContext(portalNavContext);
  const { setAlert } = useContext(alertContext);

  const initialFValues = {
    projectName: '',
    projectDescription: '',
    customer: '',
    start_date: null,
    due_date: null,
    completion_date: null,
    active: true,
  };
  const [newProject, setProjectSubmissionResponse] = useApi(() =>
    postResource('projects', values)
  );

  const validate = () => {
    console.log(values);
  };

  const createProject = async () => {
    setProjectSubmissionResponse();
  };

  useEffect(() => {
    var newProjectList = [];
    if (
      newProject.error === null &&
      !newProject.isFetching &&
      newProject.isSuccess
    ) {
      projects.map((project) => newProjectList.push(project));
      newProjectList.push(newProject.data);

      setProjects(newProjectList);
      loadContent('projectListing');
      resetForm();
      setAlert(true, 'Project Created', 'success');
    } else if (newProject.error !== null) {
      setAlert(true, 'Server Error Project', 'error');
    }
    // eslint-disable-next-line
  }, [newProject]);

  const cancelCreate = () => {
    loadContent('projectListing');
    resetForm();
  };

  const { values, errors, resetForm, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  return (
    <div>
      <Paper elevation={4} sx={{ padding: '40px' }}>
        <Form onSubmit={createProject}>
          <Grid container>
            <Grid item>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
              >
                <AccountTreeIcon
                  sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                />{' '}
                Create New Project
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} flexDirection="row">
            <Grid item xs={12}>
              <Controls.Input
                label="Project Name"
                name="projectName"
                value={values.projectName}
                onChange={handleInputChange}
                error={errors.projectName}
                fullWidth
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
                color="info"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Controls.Input
                  label="Project Customer"
                  name="customer"
                  value={values.customers}
                  onChange={handleInputChange}
                  error={errors.customers}
                  fullWidth
                  sx={{
                    marginRight: '16px',
                    marginTop: '8px',
                    marginBottom: '8px',
                    minWidth: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} flexDirection="row">
            <Grid item xs={12}>
              <Controls.Input
                label="Project Description"
                name="projectDescription"
                value={values.projectDescription}
                onChange={handleInputChange}
                error={errors.projectDescription}
                multiline
                rows={4}
                fullWidth
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="row" sx={{ mt: '16px' }}>
            <Grid item xs={12} md={6}>
              <Controls.DateInput
                label="Project Start"
                name="start_date"
                value={values.start_date}
                onChange={handleInputChange}
                error={errors.start_date}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ pl: { xs: 0, sm: '8px' }, mt: { xs: '8px', sm: '0' } }}
            >
              <Controls.DateInput
                label="Project Due"
                name="due_date"
                value={values.due_date}
                onChange={handleInputChange}
                error={errors.due_date}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: (theme) => theme.spacing(2),
            }}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Grid item>
              <Controls.CreateButton
                text="Cancel"
                color="warning"
                onClick={cancelCreate}
              />
            </Grid>
            <Grid item>
              <Controls.CreateButton
                text="Create"
                color="success"
                onClick={createProject}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </div>
  );
};

export default CreateProject;
