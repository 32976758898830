import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import moment from 'moment';
import AuthContext from '../context/auth/authContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material/';

const SessionTimeout = () => {
  const { isAuthenticated, logoutUser } = useContext(AuthContext);
  const [events, setEvents] = useState(['click', 'load', 'scroll']);
  const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 60000);
  };

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 30;
      const popTime = 29;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();

      if (minPast === popTime) {
        setSecond(leftSecond);
        setOpen(true);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem('lastTimeStamp');
        logoutUser();
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated) {
      timeStamp = moment().format();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }
    timeChecker();
    setOpen(false);
  }, [isAuthenticated]);

  // handle close popup
  const handleClose = () => {
    setOpen(false);

    resetTimer();
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    // Run the timeChecker
    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      // resetTimer();
    };
    //eslint-disable-next-line
  }, [events]);

  useEffect(() => {
    resetTimer();
    //eslint-disable-next-line
  }, []);

  const logoutTheUser = () => {
    setOpen(false);
    clearInterval(warningInactiveInterval.current);
    sessionStorage.removeItem('lastTimeStamp');
    logoutUser();
  };

  if (!isOpen) {
    return null;
  } else {
    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">INACTIVITY WARNING</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This session has been inactive for 30 minutes. You will
            automatically be logged out in {second} seconds.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logoutTheUser}>Logout</Button>
          <Button onClick={handleClose} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};

export default SessionTimeout;
