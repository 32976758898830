import React, { useContext } from 'react';
import { Toolbar, IconButton, Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import PortalContent from './PortalContent';
import PortalNavContext from '../../context/portalNav/portalNavContext';
import MuiAppBar from '@mui/material/AppBar';
import SessionTimeout from '../../utils/SessionTimeout';

const drawerWidth = 0;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  background: '#3f3b3b',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DesktopPortal = ({ Copyright }, props) => {
  const { loadModule } = useContext(PortalNavContext);

  return (
    <div>
      <Box>
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => loadModule({ module: 'menu', content: 'main' })}
              sx={{
                marginRight: '36px',
              }}
            >
              <MenuIcon style={{ color: '#feb062' }} />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="#feb062"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Project Manager
            </Typography>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <PortalContent />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <SessionTimeout />
    </div>
  );
};

export default DesktopPortal;
