import React, { useContext, useEffect } from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useForm, Form } from '../../../../components/controls/useForm';
import Controls from '../../../../components/controls/Controls';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import portalNavContext from '../../../../context/portalNav/portalNavContext';
import { useApi } from '../../../../components/api/useApi';
import { postResource } from '../../../../components/api/ApiFunctions';
import alertContext from '../../../../context/alert/alertContext';

const CreateProjectTask = ({ focusedProject }) => {
  const { loadContent } = useContext(portalNavContext);
  const { setAlert } = useContext(alertContext);

  const initialFValues = {
    task: '',
    taskDescription: '',
    taskCategory: '',
    taskProjectId: null,
    startDate: null,
    dueDate: null,
    completionDate: null,
    active: true,
    steps: [],
  };
  const [newTask, setTaskSubmissionResponse] = useApi(() =>
    postResource('tasks', values)
  );

  const validate = () => {
    console.log(values);
  };

  const createTask = async () => {
    setTaskSubmissionResponse();
  };

  const handleCompletionChange = (e) => {
    console.log(e.target.value);
    if (e.target.value !== null) {
      setValues({ ...values, active: false, completionDate: e.target.value });
    }
    if (e.target.value === null) {
      setValues({ ...values, active: true, completionDate: e.target.value });
    }
  };

  useEffect(() => {
    if (newTask.error === null && !newTask.isFetching && newTask.isSuccess) {
      loadContent('viewProject');
      resetForm();
      setAlert(true, 'Task Created', 'success');
    } else if (newTask.error !== null) {
      setAlert(true, 'Server Error Project', 'error');
    }
    // eslint-disable-next-line
  }, [newTask]);

  useEffect(() => {
    if (focusedProject !== null) {
      setValues({ ...values, taskProjectId: focusedProject._id });
    }
    // eslint-disable-next-line
  }, [focusedProject]);

  const cancelCreate = () => {
    loadContent('viewProject');
    resetForm();
  };

  const { values, setValues, errors, resetForm, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  return (
    <div>
      <Paper elevation={4} sx={{ padding: '40px' }}>
        <Form onSubmit={createTask}>
          <Grid container>
            <Grid item>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
              >
                <AccountTreeIcon
                  sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                />{' '}
                Create New Project Task
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} flexDirection="row">
            <Grid item xs={12}>
              <Controls.Input
                label="Task"
                name="task"
                value={values.task}
                onChange={handleInputChange}
                error={errors.task}
                fullWidth
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
                color="info"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} flexDirection="row">
            <Grid item xs={12}>
              <Controls.Input
                label="Module"
                name="taskCategory"
                value={values.taskCategory}
                onChange={handleInputChange}
                error={errors.taskCategory}
                fullWidth
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
                color="info"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} flexDirection="row">
            <Grid item xs={12}>
              <Controls.Input
                label="Task Details"
                name="taskDetails"
                value={values.taskDetails}
                onChange={handleInputChange}
                error={errors.taskDetails}
                multiline
                rows={4}
                fullWidth
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="row" sx={{ mt: '16px' }}>
            <Grid item xs={12} md={4}>
              <Controls.DateInput
                label="Project Start"
                name="startDate"
                value={values.startDate}
                onChange={handleInputChange}
                error={errors.startDate}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ pl: { xs: 0, sm: '8px' }, mt: { xs: '8px', sm: '0' } }}
            >
              <Controls.DateInput
                label="Due Date"
                name="dueDate"
                value={values.dueDate}
                onChange={handleInputChange}
                error={errors.dueDate}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ pl: { xs: 0, sm: '8px' }, mt: { xs: '8px', sm: '0' } }}
            >
              <Controls.DateInput
                label="Completion Date"
                name="completionDate"
                value={values.completionDate}
                onChange={handleCompletionChange}
                error={errors.completionDate}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: (theme) => theme.spacing(2),
            }}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Grid item>
              <Controls.CreateButton
                text="Cancel"
                color="warning"
                onClick={cancelCreate}
              />
            </Grid>
            <Grid item>
              <Controls.CreateButton
                text="Create"
                color="success"
                onClick={createTask}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </div>
  );
};

export default CreateProjectTask;
