import React, { useContext, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useForm, Form } from '../../../components/controls/useForm';
import Controls from '../../../components/controls/Controls';
import portalNavContext from '../../../context/portalNav/portalNavContext';
import { useApi } from '../../../components/api/useApi';
import { postResource } from '../../../components/api/ApiFunctions';
import alertContext from '../../../context/alert/alertContext';

const CreateTicket = ({ tickets, setTickets, projects, contacts }) => {
  const { loadContent } = useContext(portalNavContext);
  const { setAlert } = useContext(alertContext);

  const initialFValues = {
    ticketOverview: '',
    ticketDetails: '',
    ticketCategory: '',
    ticketCustomerId: '',
    ticketProjectId: '',
    ticketOwnerId: '61525228db67dc60680dd811',
    status: 'New',
    active: true,
  };

  const [newTicket, setTicketSubmissionResponse] = useApi(() =>
    postResource('tickets', values)
  );

  const validate = () => {
    console.log(values);
  };

  const createTicket = async () => {
    setTicketSubmissionResponse();
  };

  useEffect(() => {
    var newTicketList = [];
    if (
      newTicket.error === null &&
      !newTicket.isFetching &&
      newTicket.isSuccess
    ) {
      tickets.map((ticket) => newTicketList.push(ticket));
      newTicketList.push(newTicket.data);

      setTickets(newTicketList);
      loadContent('ticketList');
      resetForm();
      setAlert(true, 'Ticket Created', 'success');
    } else if (newTicket.error !== null) {
      setAlert(true, 'Server Error Ticket', 'error');
    }
    // eslint-disable-next-line
  }, [newTicket]);

  const cancelCreate = () => {
    loadContent('ticketList');
    resetForm();
  };

  const handleSelect = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const { values, resetForm, handleInputChange, setValues } = useForm(
    initialFValues,
    false,
    validate
  );

  return (
    <>
      <Paper elevation={4} sx={{ padding: '40px' }}>
        <Form onSubmit={createTicket}>
          <Grid container>
            <Grid item>
              <Typography
                variant="h4"
                component="h2"
                gutterBottoms
                sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
              >
                Create New Ticket
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} flexDirection="row">
            <Grid item xs={12}>
              <Controls.Input
                label="Overview"
                name="ticketOverview"
                value={values.ticketOverview}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
                color="info"
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                label="Details"
                name="ticketDetails"
                value={values.ticketDetails}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={8}
                sx={{
                  marginRight: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minWidth: '100%',
                }}
                color="info"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" sx={{ minWidth: '98%' }}>
                <InputLabel>Customer</InputLabel>
                <Select
                  name="ticketCustomerId"
                  value={values.ticketCustomerId}
                  onChange={handleSelect}
                  options={contacts}
                  sx={{ minWidth: '200px' }}
                >
                  <MenuItem key="ALL" value=""></MenuItem>
                  {contacts !== null &&
                    contacts.map((contact) => (
                      <MenuItem key={contact._id} value={contact._id}>
                        {contact.firstName + ' ' + contact.lastName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" sx={{ minWidth: '100%' }}>
                <InputLabel>Project</InputLabel>
                <Select
                  name="ticketProjectId"
                  value={values.ticketProjectId}
                  onChange={handleSelect}
                  options={projects}
                >
                  <MenuItem key="ALL" value=""></MenuItem>
                  {projects !== null &&
                    projects.map((project) => (
                      <MenuItem key={project._id} value={project._id}>
                        {project.projectName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" sx={{ minWidth: '98%' }}>
                <InputLabel>Category</InputLabel>
                <Select
                  name="ticketCategory"
                  value={values.ticketCategory}
                  onChange={handleSelect}
                >
                  <MenuItem key="bug" value="Bug">
                    Bug
                  </MenuItem>
                  <MenuItem key="feature" value="Feature Request">
                    Feature Request
                  </MenuItem>
                  <MenuItem key="trouble" value="Troubleshoot">
                    Troubleshoot
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: (theme) => theme.spacing(2),
            }}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Grid item>
              <Controls.CreateButton
                text="Cancel"
                color="warning"
                onClick={cancelCreate}
              />
            </Grid>
            <Grid item>
              <Controls.CreateButton
                text="Create"
                color="success"
                onClick={createTicket}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </>
  );
};

export default CreateTicket;
