import React from 'react';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';

export default function DateInput({ name, label, value, onChange, ...other }) {
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          name={name}
          value={value}
          onChange={(date) => onChange(convertToDefEventPara(name, date))}
          renderInput={(params) => (
            <TextField {...params} sx={{ minWidth: '100%' }} />
          )}
          {...other}
        />
      </LocalizationProvider>
    </div>
  );
}
