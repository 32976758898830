import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Grid, TextField, Button } from '@mui/material';
import { putResource } from '../../../../components/api/ApiFunctions';

const EditProjectNote = ({
  focusedProject,
  toggleCreateNote,
  setFocusedProject,
  projects,
  setProjects,
  noteToEdit,
  setShowEditNote,
}) => {
  const [projectNote, setProjectNote] = useState(null);

  useEffect(() => {
    if (noteToEdit !== null) {
      setProjectNote(noteToEdit);
    }
  }, [noteToEdit]);

  const handleInput = (e) => {
    setProjectNote({ ...projectNote, [e.target.id]: e.target.value });
  };

  const updateProjects = (updatedProject) => {
    var updatedProjectsList = [];

    const filteredProjects = projects.filter(
      (project) => project._id !== focusedProject._id
    );

    filteredProjects.map((project) => updatedProjectsList.push(project));

    updatedProjectsList.push(updatedProject);

    setProjects(updatedProjectsList);
  };

  const saveNewNote = async () => {
    var notesList = [];
    const filteredList = focusedProject.notes.filter(
      (note) => note._id !== noteToEdit._id
    );
    filteredList.map((note) => notesList.push(note));
    notesList.push(projectNote);

    const updateData = {
      _id: focusedProject._id,
      notes: notesList,
    };

    setFocusedProject({ ...focusedProject, notes: notesList });

    putResource('projects', updateData, focusedProject._id)
      .then((response) => {
        updateProjects(response[0]);
      })
      .catch((error) => {
        console.log(error);
      });
    setShowEditNote(false);
  };
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      {projectNote !== null ? (
        <Paper
          sx={{ width: '100%', mb: 2, padding: ' 32px 32px 8px 32px' }}
          elevation={6}
        >
          <Typography variant="h6">Create</Typography>
          <TextField
            variant="outlined"
            label="Title"
            name="title"
            id="title"
            value={projectNote.title}
            sx={{ width: '90%', mt: 2 }}
            onChange={handleInput}
          />
          <TextField
            variant="outlined"
            multiline
            rows="6"
            id="projectNote"
            name="projectNote"
            value={projectNote.projectNote}
            sx={{ width: '90%', mt: 2 }}
            onChange={handleInput}
          />
          <Grid container sx={{ mt: 2, justifyContent: 'flex-end' }}>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={() => setShowEditNote(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ ml: 1 }}
                onClick={saveNewNote}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
    </Box>
  );
};

export default EditProjectNote;
