import {
  Card,
  Paper,
  CardContent,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  Backdrop,
  Box,
  Modal,
  Fade,
  Tooltip,
  Button,
  TextField,
} from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import PortalNavContext from '../../../context/portalNav/portalNavContext';
import mmddyy from '../../../utils/mmddyy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { deleteResource } from '../../../components/api/ApiFunctions';
import { IconButton } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { putResource } from '../../../components/api/ApiFunctions';
import NotesList from './ticketNotes/NotesList';
import CreateNote from './ticketNotes/CreateNote';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ViewTicket = ({
  tickets,
  setTickets,
  users,
  projects,
  contacts,
  focusedTicket,
  setFocusedTicket,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { loadContent } = useContext(PortalNavContext);
  const lookupProject = (id) => {
    const project = projects.filter((project) => project._id === id);
    return project[0].projectName;
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const cancelTicketCompletion = () => {
    setFocusedTicket({ ...focusedTicket, resolution: '' });
    handleClose();
  };
  const onChange = (e) => {
    setFocusedTicket({ ...focusedTicket, [e.target.name]: e.target.value });
  };

  const lookupOwner = (id) => {
    const owner = users.filter((user) => user._id === id);
    return owner[0].First_Name + ' ' + owner[0].Last_Name;
  };
  const lookupContact = (id) => {
    const contact = contacts.filter((contact) => contact._id === id);
    return contact[0].firstName + ' ' + contact[0].lastName;
  };

  const completeTicket = () => {
    const ticketUpdates = {
      _id: focusedTicket._id,
      resolution: focusedTicket.resolution,
      completionDate: Date.now(),
      active: false,
      status: 'Closed',
    };

    setFocusedTicket({
      ...focusedTicket,
      completionDate: ticketUpdates.completionDate,
      active: false,
      status: 'Closed',
    });

    putResource('tickets', ticketUpdates, focusedTicket._id);

    handleClose();
  };

  const editTicket = () => {
    loadContent('editTicket');
  };

  const deleteTicket = () => {
    deleteResource('tickets', focusedTicket._id);
    const existingTickets = tickets.filter(
      (ticket) => ticket._id !== focusedTicket._id
    );
    setTickets(existingTickets);
    loadContent('ticketList');
    setFocusedTicket(null);
  };

  const returnToList = () => {
    var updatedTicketList = [];

    const filteredList = tickets.filter(
      (ticket) => ticket._id !== focusedTicket._id
    );

    filteredList.map((ticket) => updatedTicketList.push(ticket));

    updatedTicketList.push(focusedTicket);

    setTickets(updatedTicketList);

    loadContent('ticketList');
    setFocusedTicket(null);
  };

  return (
    <Paper elevation={6} sx={{ p: 4 }}>
      {focusedTicket !== null && (
        <div>
          <Grid container xs={12} justifyContent="flex-end">
            <Grid item xs={4}>
              <Tooltip title="Back">
                <IconButton onClick={returnToList}>
                  <ArrowBackIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={8}>
              <Tooltip title="Delete Ticket">
                <IconButton onClick={deleteTicket} sx={{ float: 'right' }}>
                  <DeleteForeverIcon fontSize="medium" color="error" />
                </IconButton>
              </Tooltip>
              {focusedTicket.active === true && (
                <Tooltip title="Complete Ticket">
                  <IconButton onClick={handleOpen} sx={{ float: 'right' }}>
                    <AddTaskIcon fontSize="medium" color="success" />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Edit Ticket">
                <IconButton onClick={editTicket} sx={{ float: 'right' }}>
                  <EditIcon fontSize="medium" color="warning" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Card elevation={4} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6">
                {' '}
                {focusedTicket.ticketOverview}{' '}
              </Typography>
              <Table size="small" sx={{ border: 'none' }}>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Customer:</TableCell>
                    <TableCell>
                      {lookupContact(focusedTicket.ticketCustomerId)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Ticket #
                    </TableCell>
                    <TableCell>{focusedTicket.ticketNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Project:</TableCell>
                    <TableCell>
                      {lookupProject(focusedTicket.ticketProjectId)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Created:
                    </TableCell>
                    <TableCell>{mmddyy(focusedTicket.createdAt)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Owner:</TableCell>
                    <TableCell>
                      {lookupOwner(focusedTicket.ticketOwnerId)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Completion:
                    </TableCell>
                    <TableCell>
                      {focusedTicket.completionDate !== null &&
                      focusedTicket.completionDate !== undefined
                        ? mmddyy(focusedTicket.completionDate)
                        : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Category:</TableCell>
                    <TableCell>{focusedTicket.ticketCategory}</TableCell>

                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Status:
                    </TableCell>
                    <TableCell>{focusedTicket.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Disposition:
                    </TableCell>
                    <TableCell>
                      {focusedTicket.active === true ? 'OPEN' : 'CLOSED'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2, p: 2 }}>
            <CardContent>
              <Typography variant="h6"> DETAILS: </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {focusedTicket.ticketDetails}
              </Typography>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2, p: 2 }}>
            <CardContent>
              <Typography variant="h6"> RESOLUTION: </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {focusedTicket.resolution}
              </Typography>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2, p: 2 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6"> NOTES: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ float: 'right' }}
                  >
                    NEW
                  </Button>
                </Grid>
              </Grid>
              <CreateNote />
              <NotesList notes={focusedTicket.ticketNotes} />
            </CardContent>
          </Card>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <Box sx={style}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                      color="primary"
                    >
                      Close Ticket
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      variant="outlined"
                      name="resolution"
                      id="resolution"
                      label="Ticket Resolution"
                      onChange={onChange}
                      value={focusedTicket.resolution}
                      multiline
                      rows="8"
                      color="secondary"
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button
                      color="success"
                      variant="contained"
                      sx={{ float: 'right' }}
                      onClick={completeTicket}
                    >
                      Complete
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      sx={{ float: 'left' }}
                      onClick={cancelTicketCompletion}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          </Modal>
        </div>
      )}
    </Paper>
  );
};

export default ViewTicket;
