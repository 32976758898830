import React, { useContext, useState, useEffect } from 'react';
import PortalNavContext from '../../../context/portalNav/portalNavContext';
import ProjectList from './ProjectList';
import CreateProject from './CreateProject';
import EditProject from './EditProject';
import ViewProject from './ViewProject';
import CreateProjectTask from './tasks/CreateProjectTask';
import EditProjectTask from './tasks/EditProjectTask';
import { useApi } from '../../../components/api/useApi';
import { fetchResources } from '../../../components/api/ApiFunctions';

const Projects = () => {
  const { content } = useContext(PortalNavContext);
  const [focusedProject, setFocusedProject] = useState(null);
  const [focusedTask, setFocusedTask] = useState(null);
  const [projects, setProjects] = useState(null);
  const [projectData, setProjectDataResponse] = useApi(() =>
    fetchResources('projects')
  );

  useEffect(() => {
    setProjectDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProjects(projectData.data);
    // eslint-disable-next-line
  }, [projectData]);

  if (projects !== null) {
    switch (content) {
      default:
      case 'projectList':
        return (
          <ProjectList
            setFocusedProject={setFocusedProject}
            projects={projects}
          />
        );
      case 'createProject':
        return <CreateProject projects={projects} setProjects={setProjects} />;
      case 'editProject':
        return (
          <EditProject
            focusedProject={focusedProject}
            projects={projects}
            setProjects={setProjects}
          />
        );
      case 'viewProject':
        return (
          <ViewProject
            focusedProject={focusedProject}
            setFocusedProject={setFocusedProject}
            projects={projects}
            setProjects={setProjects}
            setFocusedTask={setFocusedTask}
          />
        );
      case 'createProjectTask':
        return <CreateProjectTask focusedProject={focusedProject} />;
      case 'editProjectTask':
        return (
          <EditProjectTask
            focusedTask={focusedTask}
            setFocusedTask={setFocusedTask}
          />
        );
    }
  } else {
    return <div>LOADING</div>;
  }
};

export default Projects;
