import React, { useEffect } from 'react';
import {
  TextField,
  Typography,
  Grid,
  Button,
  Paper,
  Card,
  CardContent,
  Container,
} from '@mui/material';
import { useApi } from '../../../components/api/useApi';
import { postResource } from '../../../components/api/ApiFunctions';
import { useForm, Form } from '../../../components/controls/useForm';

const NewContact = ({ setContactPage, setContactsList, contactsList }) => {
  const initialFValues = {
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    mobile: '',
    email: '',
    active: true,
  };

  const [newContact, setContactSubmissionResponse] = useApi(() =>
    postResource('contacts', values)
  );

  useEffect(() => {
    var newContactList = [];
    if (
      newContact.error === null &&
      newContact.isSuccess &&
      !newContact.isFetching
    ) {
      contactsList.map((contact) => newContactList.push(contact));
      newContactList.push(newContact.data);

      setContactsList(newContactList);
      setContactPage('contactList');
    }
    // eslint-disable-next-line
  }, [newContact]);

  const createContact = () => {
    setContactSubmissionResponse();
  };

  const cancelCreate = () => {
    setContactPage('contactListing');
    resetForm();
  };

  const { values, errors, resetForm, handleInputChange } = useForm(
    initialFValues,
    false
  );

  return (
    <Container>
      <Paper elevation={6} sx={{ p: 4, background: '#f5f5f5' }}>
        <Card elevation={4}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ my: 2 }}>
                  Create Contact
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  name="address"
                  value={values.address}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  name="address2"
                  value={values.address2}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  value={values.city}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%' }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  label="State"
                  name="state"
                  value={values.state}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Zip"
                  name="zip"
                  value={values.zip}
                  onChange={handleInputChange}
                  sx={{
                    minWidth: '82%',
                    ml: { xs: 0, sm: 1 },
                    mt: { xs: 2, sm: 0 },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Mobile"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%', mt: { xs: 2, sm: 0 } }}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  sx={{ minWidth: '90%' }}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button
                  color="error"
                  variant="contained"
                  onClick={cancelCreate}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="success"
                  variant="contained"
                  sx={{ float: 'right' }}
                  onClick={createContact}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default NewContact;
