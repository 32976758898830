import React from 'react';
import TextField from '@mui/material/TextField';

export default function Input({
  variant,
  name,
  label,
  value,
  error = null,
  onChange,
  ...other
}) {
  return (
    <TextField
      variant={variant || 'outlined'}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      {...other}
      // {...TextField(error && { error: true, helperText: error })}
    ></TextField>
  );
}
