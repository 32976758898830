import React, { useContext, useEffect, useState } from 'react';
import PortalNavContext from '../../../context/portalNav/portalNavContext';
import {
  Container,
  Card,
  CardContent,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import mmddyy from '../../../utils/mmddyy';

const TicketList = ({
  setFocusedTicket,
  tickets,
  users,
  projects,
  contacts,
}) => {
  const { loadContent } = useContext(PortalNavContext);
  const [openTickets, setOpenTickets] = useState(null);
  const [closedTickets, setClosedTickets] = useState(null);

  useEffect(() => {
    if (tickets !== null) {
      const open = tickets.filter((ticket) => ticket.active === true);
      const closed = tickets.filter((ticket) => ticket.active === false);
      setOpenTickets(open);
      setClosedTickets(closed);
    }

    //eslint-disable-next-line
  }, [tickets]);

  const lookupProject = (id) => {
    if (projects !== null) {
      const project = projects.filter((project) => project._id === id);
      if (project[0] !== undefined) {
        return project[0].projectName;
      } else {
        return '';
      }
    } else {
      return 'loading';
    }
  };
  const lookupCustomer = (id) => {
    if (contacts !== null) {
      const contact = contacts.filter((contact) => contact._id === id);
      const name = contact[0].firstName + ' ' + contact[0].lastName;
      return name;
    } else {
      return 'loading';
    }
  };

  const viewTicket = (ticket) => {
    loadContent('viewTicket');
    setFocusedTicket(ticket);
  };

  return (
    <>
      <Container sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Paper elvation={6} sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" color="#feb062">
                Tickets
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="success"
                onClick={() => loadContent('createTicket')}
                variant="contained"
                size="small"
                sx={{ float: 'right', mr: 3 }}
              >
                New
              </Button>
            </Grid>
          </Grid>
          <Card elvation={4} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" color="#feb062">
                Open Tickets
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ background: '#575151', color: '#feb062' }}>
                    <TableCell sx={{ textAlign: 'left', color: '#feb062' }}>
                      #
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', color: '#feb062' }}>
                      Overview
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: '#feb062' }}>
                      Contact
                    </TableCell>
                    <TableCell
                      sx={{
                        display: { xs: 'none', sm: 'table-cell' },
                        textAlign: 'center',
                        color: '#feb062',
                      }}
                    >
                      Project
                    </TableCell>

                    <TableCell sx={{ textAlign: 'center', color: '#feb062' }}>
                      Created
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', color: '#feb062' }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {openTickets !== null
                    ? openTickets.map((ticket) => (
                        <TableRow
                          key={ticket._id}
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell onClick={() => viewTicket(ticket)}>
                            {ticket.ticketNumber}
                          </TableCell>
                          <TableCell onClick={() => viewTicket(ticket)}>
                            {ticket.ticketOverview}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {lookupCustomer(ticket.ticketCustomerId)}
                          </TableCell>
                          <TableCell
                            sx={{
                              display: { xs: 'none', sm: 'table-cell' },
                              textAlign: 'center',
                            }}
                          >
                            {lookupProject(ticket.ticketProjectId)}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {mmddyy(ticket.createdAt)}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>
                            {ticket.status}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card elvation={4} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" color="#feb062">
                Closed Tickets
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ background: '#575151', color: '#feb062' }}>
                    <TableCell sx={{ textAlign: 'left', color: '#feb062' }}>
                      #
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', color: '#feb062' }}>
                      Overview
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: '#feb062' }}>
                      Contact
                    </TableCell>
                    <TableCell
                      sx={{
                        display: { xs: 'none', sm: 'table-cell' },
                        textAlign: 'center',
                        color: '#feb062',
                      }}
                    >
                      Project
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: '#feb062' }}>
                      Created
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: '#feb062' }}>
                      Completed
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {closedTickets !== null
                    ? closedTickets.map((ticket) => (
                        <TableRow
                          key={ticket._id}
                          hover
                          onClick={() => viewTicket(ticket)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell>{ticket.ticketNumber}</TableCell>
                          <TableCell>{ticket.ticketOverview}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {lookupCustomer(ticket.ticketCustomerId)}
                          </TableCell>
                          <TableCell
                            sx={{
                              display: { xs: 'none', sm: 'table-cell' },
                              textAlign: 'center',
                            }}
                          >
                            {lookupProject(ticket.ticketProjectId)}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {mmddyy(ticket.createdAt)}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {mmddyy(ticket.completionDate)}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Paper>
      </Container>
      <Container sx={{ display: { xs: 'block', sm: 'none' } }}>
        {openTickets !== null
          ? openTickets.map((ticket) => (
              <Card elevation={6}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        Ticket# {ticket.ticketNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">
                        {mmddyy(ticket.createdAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
          : null}
      </Container>
    </>
  );
};

export default TicketList;
